.ag-root-wrapper-body.ag-layout-normal {
  height: 100% !important;
}

.frame-detail{
  border-radius: 5px;
  border: 1px solid #6b6b76;
  padding: 10px;
  margin: 10px 0px;
}

.modal-sub .mw-250{
  max-width: none;
}

.w-100percent {
  width: 100% !important;
}

.h-100percent {
  height: 100% !important;
}

.min-h-600 {
  min-height: 600px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.mw-250 {
  min-width: 250px;
  max-width: 250px;
}

.ant-select-selection-item {
  height: auto !important;
  white-space: unset;
}

.ant-select-selection-item .ant-select-selection-item-content {
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
  white-space: unset;
  width: 100%;
}

.ant-select-multiple .ant-select-selection-item {
  flex: unset !important;
  width: 100%;
}

.ag-header-cell,
.ag-cell{
  padding: 0px 10px !important;
}

.pointer {
  cursor: pointer;
}

.w-fit {
  width: fit-content !important;
}

.no-gutters {
  margin-left: 0 !important;
  margin-right: 0 !important;
  --bs-gutter-x: 0 !important;
}

.relative {
  position: relative;
}

.h-minus-180 {
  height: calc(100vh - 180px);
}

.h-minus-200 {
  height: calc(100vh - 200px);
}

.h-minus-230 {
  height: calc(100vh - 230px);
}

.h-minus-250 {
  height: calc(100vh - 250px);
}

.h-minus-300 {
  height: calc(100vh - 300px);
}
